<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 834px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header">
          <h5 class="main-form-control__title" id="staticBackdropLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :disabled="state.isFormLoading"
          ></button>
        </div>

        <div
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
        >
          <div class="add-staff px-5 py-3">
            <div>
              <label class="main-form-control__label">
                Название продукта:
              </label>

              <div class="main-form-control__input-wrapper">
                <input
                  class="main-form-control__input"
                  type="text"
                  placeholder="Введите название"
                  :value="state.title"
                  disabled
                />
                <div class="main-form-control__input-svg-wrapper">
                  <inline-svg
                    :src="require('@/assets/images/lock.svg')"
                    fill="#717C99"
                  />
                </div>
              </div>
            </div>

            <div>
              <label class="main-form-control__label"> Название заявки: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Название заявки"
                :value="state.deal_title"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Цех: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Цех"
                :value="state.manufacture"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Участок: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Участок"
                :value="state.area"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Склад: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Склад"
                :value="state.stock?.title"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label">
                Планируемое время:
              </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Планируемое время"
                :value="state.need_time"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Итоговое время: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Итоговое время"
                :value="`${padZero(timer.days)}:${padZero(
                  timer.hours
                )}:${padZero(timer.minutes)}`"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Исполнитель: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Исполнитель"
                :value="state.responsibles?.join(' ,')"
                disabled
              />
            </div>

            <div>
              <label class="main-form-control__label"> Кол-во: </label>

              <input
                class="main-form-control__input"
                type="text"
                placeholder="Кол-во"
                :value="state.quantity"
                disabled
              />
            </div>

            <span
              class="line-separator line-separator--light column-span-2"
            ></span>

            <div class="mb-3">
              <label class="main-form-control__label">
                Фактическое количество:
              </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.real_quantity.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.real_quantity"
              />

              <span class="error-warning" v-if="v$.real_quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="mb-3">
              <label class="main-form-control__label"> Кол-во брака: </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.defect_quantity.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.defect_quantity"
              />

              <span class="error-warning" v-if="v$.defect_quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div>
              <label class="main-form-control__label"> Кол-во уценки: </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.markdown_quantity.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.markdown_quantity"
              />

              <span class="error-warning" v-if="v$.markdown_quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div>
              <label class="main-form-control__label">
                Остаточное кол-во:
              </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.rest_quantity.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.rest_quantity"
              />

              <span class="error-warning" v-if="v$.rest_quantity.$error"
                >* Данное поле обязательно</span
              >
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <div class="px-5">
            <button
              type="button"
              class="btn btn-white btn-md"
              :disabled="state.isFormLoading"
              @click="onResetForm"
            >
              Отмена
            </button>
            <button
              type="submit"
              class="btn btn-gold btn-md"
              :disabled="state.isFormLoading"
            >
              <span>{{ submitBtnText }}</span>

              <div
                v-if="state.isFormLoading"
                class="spinner-border spinner-border-sm ms-2"
                role="status"
              >
                <span class="visually-hidden">Загрузка...</span>
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import useModal from "@/mixins/useModal";
import { required, numeric } from "@vuelidate/validators";

export default {
  props: {
    entityToUpdate: {
      type: Object,
      required: false,
    },
    timer: {
      type: Object,
      required: false,
    },
    padZero: {
      type: Function,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    submitBtnText: {
      type: String,
      required: true,
    },
    apiRequest: {
      type: Function,
      required: true,
    },
    refetch: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      real_quantity: null,
      defect_quantity: null,
      markdown_quantity: null,
      rest_quantity: null,
    });
    const rules = {
      real_quantity: { required, numeric, minValue: (item) => item >= 1 },
      defect_quantity: { required, numeric },
      markdown_quantity: { required, numeric },
      rest_quantity: { required, numeric },
    };
    const { modalRef, onSubmitForm, v$, onResetForm } = useModal(props, {
      state,
      rules,
    });

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
    };
  },
};
</script>