<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 534px">
      <form @submit.prevent="onSubmitForm" class="modal-content">
        <div class="modal-header">
          <h5 class="main-form-control__title" id="staticBackdropLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            :disabled="state.isFormLoading"
          ></button>
        </div>

        <div
          class="modal-body order-tables__modal-form-wrapper scrollbar-light"
          :style="{ overflow: isSelectOpen ? 'hidden' : 'auto' }"
        >
          <div class="add-staff">
            <div class="column-span-2">
              <label class="main-form-control__label">
                Фактическое количество:
              </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.fact_amount.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.fact_amount"
              />

              <span class="error-warning" v-if="v$.fact_amount.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <div class="column-span-2">
              <label class="main-form-control__label">
                Количество брака:
              </label>

              <input
                class="main-form-control__input"
                :class="{ error: v$.defect_amount.$error }"
                type="text"
                placeholder="Введите количество"
                v-model="state.defect_amount"
              />

              <span class="error-warning" v-if="v$.defect_amount.$error"
                >* Данное поле обязательно</span
              >
            </div>

            <!-- <div class="column-span-2">
              <label class="main-form-control__label">
                Единица измерения:
              </label>

              <v-select
                class="main-form-control__select material-select"
                :class="{ error: v$.fact_amount.$error }"
                placeholder="Единица измерения"
                v-model="state.fact_amount"
                :options="measurementOptionsList"
                label="title"
                :reduce="(item) => item"
                :clearable="false"
                append-to-body
                :calculate-position="withPopper"
              >
                {{ option.title }}
              </v-select>

              <span class="error-warning" v-if="v$.fact_amount.$error"
                >* Данное поле обязательно</span
              >
            </div> -->
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-white btn-md"
            :disabled="state.isFormLoading"
            @click="onResetForm"
          >
            Отмена
          </button>
          <button
            type="submit"
            class="btn btn-gold btn-md"
            :disabled="state.isFormLoading"
          >
            <span>{{ submitBtnText }}</span>

            <div
              v-if="state.isFormLoading"
              class="spinner-border spinner-border-sm ms-2"
              role="status"
            >
              <span class="visually-hidden">Загрузка...</span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

import popperSelect from "@/mixins/popperSelect";
import useModal from "@/mixins/useModal";
import { required, numeric } from "@vuelidate/validators";

export default {
  mixins: [popperSelect],
  props: {
    measurementOptionsList: {
      type: Array,
      required: false,
    },
    entityToUpdate: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    submitBtnText: {
      type: String,
      required: true,
    },
    apiRequest: {
      type: Function,
      required: true,
    },
    refetch: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      isFormLoading: false,
      fact_amount: null,
    });
    const rules = {
      fact_amount: {
        required,
        numeric,
        minValue: (item) => item >= 1,
        maxValue: (item) => item <= state.quantity,
      },
      defect_amount: {
        required,
        numeric,
        maxValue: (item) => item <= state.quantity,
      },
    };
    const { modalRef, onSubmitForm, v$, onResetForm } = useModal(props, {
      state,
      rules,
    });

    return {
      modalRef,
      state,
      v$,
      onSubmitForm,
      onResetForm,
    };
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

.vs__dropdown-menu {
  z-index: 100000 !important;
}

.material-select .vs__selected {
  width: 410px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 37.5px;
}
</style>