<template>
  <section class="formation">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center">
        <router-link class="main-tab__title" :to="{ name: 'plan-page' }">
          Производство
        </router-link>
        <h2 class="main-tab__title active">Выполнение</h2>
      </div>
    </div>

    <div class="order-tables">
      <div class="order-tables__wrapper">
        <form @submit.prevent="onSubmitForm" class="order-tables__item">
          <div class="order-tables__item-top">
            <div class="d-flex flex-wrap">
              <div class="d-flex flex-column me-4">Трек времени задачи</div>
            </div>

            <div
              v-if="v$.product.$errors.length"
              class="order-tables__item-top-errors mt-2"
            >
              <div class="order-tables__item-top-error danger">
                <inline-svg
                  :src="require('@/assets/images/error.svg')"
                  stroke="#FF0558"
                />
                <span>Ошибка ввода данных</span>
              </div>
            </div>
          </div>

          <div class="order-tables__table-responsive">
            <table class="table order-tables__table">
              <thead>
                <tr>
                  <th class="text-start ps-3" scope="col">Название продукта</th>
                  <th scope="col">Количество</th>
                  <th scope="col">Сырье</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="ps-3 text-start">
                    {{ state.product?.title }}
                  </td>
                  <td>
                    {{ convertNumber(state.product?.quantity) ?? "0" }}
                    {{ state.product?.measurement?.title }}
                  </td>
                  <td>
                    <button
                      class="order-tables__material-info-btn"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#plan-material-modal"
                    >
                      <span
                        class="w-100 h-100"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        title="Подробно"
                      >
                        <inline-svg
                          width="24"
                          height="24"
                          fill="#6269FF"
                          :src="require('@/assets/images/info.svg')"
                        />
                      </span>
                    </button>
                  </td>
                  <td>
                    <div
                      class="d-inline-flex align-items-center justify-content-start w-100"
                    >
                      <button
                        class="order-tables__material-time-btn me-3"
                        type="button"
                        @click="stopTimer"
                        :disabled="
                          !timerFunction ||
                          state.product?.status == 3 ||
                          state.tasksToResolve?.length > 0
                        "
                      >
                        <inline-svg
                          :src="require('@/assets/images/pause.svg')"
                          fill="#FF6363"
                        />
                      </button>

                      <button
                        class="order-tables__material-time-btn"
                        type="button"
                        @click="startTimer"
                        :disabled="
                          timerFunction ||
                          state.product?.status == 3 ||
                          state.tasksToResolve?.length > 0
                        "
                      >
                        <inline-svg
                          :src="require('@/assets/images/play.svg')"
                          fill="#02C887"
                        />
                      </button>

                      <div
                        class="order-tables__timer ms-3"
                        style="width: 120px"
                      >
                        {{ padZero(timer.days) }}:{{ padZero(timer.hours) }}:{{
                          padZero(timer.minutes)
                        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            v-if="state.tasksToResolve?.length > 0"
            class="alert alert-danger"
            style="margin: 20px 13px 0 13px; font-size: 16px"
            role="alert"
          >
            Вам необходимо закончить
            {{ state.tasksToResolve?.length > 1 ? "задачи" : "задачу" }}
            <router-link
              v-for="(task, index) in state.tasksToResolve"
              :key="task"
              class="main-link mx-1"
              :to="{
                name: 'plan-task-page',
                params: { id: parseInt(task) },
              }"
              >{{
                index == state.tasksToResolve?.length - 1 ? task : task + ","
              }}</router-link
            >
            перед тем, как приступить к этой.
          </div>

          <div class="order-tables__item-bottom">
            <div class="d-flex flex-wrap">
              <button
                type="button"
                class="btn btn-md btn-gold w-100 me-3 my-1"
                style="max-width: 230px"
                @click="startTimer"
                :disabled="
                  timerFunction ||
                  state.product?.status == 3 ||
                  state.tasksToResolve?.length > 0
                "
              >
                {{ timer.lastUpdated ? "Продолжить" : "Начать" }}
              </button>

              <button
                type="submit"
                class="btn btn-md btn-warning text-light w-100 me-3 my-1"
                style="max-width: 230px"
                @click="stopTimer"
                :disabled="
                  !timerFunction ||
                  state.product?.status == 3 ||
                  state.tasksToResolve?.length > 0
                "
              >
                Стоп
              </button>

              <button
                class="btn btn-md btn-error text-nowrap w-100 my-1"
                style="max-width: 230px"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#task-finish-modal"
                :disabled="
                  !timer.lastUpdated ||
                  state.product?.status == 3 ||
                  state.tasksToResolve?.length > 0
                "
              >
                Завершить частично
              </button>

              <!-- <button
                type="submit"
                class="btn btn-md btn-error w-100 my-1"
                style="max-width: 230px"
                :disabled="
                  !timer.lastUpdated ||
                  state.product?.status == 3 ||
                  state.tasksToResolve?.length > 0 ||
                  !state.product?.is_finishible
                "
              >
                Закончить
              </button> -->
            </div>

            <span
              v-if="state.product?.status == 3"
              class="d-block text-success mt-1"
              style="font-size: 13px"
              >* Задача успешно завершена</span
            >
          </div>
        </form>
      </div>
    </div>
  </section>

  <template v-if="state.product?.logs?.length > 0">
    <h2 class="formation__table-title my-4">
      <span>Этапы выполнения работы</span>
    </h2>

    <div
      class="order-tables__table-responsive table-responsive scrollbar-light mb-5"
      style="overflow-y: hidden"
    >
      <table class="table order-tables__table">
        <thead>
          <tr>
            <th scope="col" style="width: 50px">№</th>
            <th scope="col">Время начала</th>
            <th scope="col">Время завершения</th>
            <th scope="col">Затраченное время</th>
            <th scope="col">Фактическое количество</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in state.product.logs" :key="item.id">
            <td>{{ ++index }}</td>

            <td>
              {{
                item.start_date_time
                  ? moment(new Date(item.start_date_time)).format(
                      "HH:mm DD.MM.YYYY"
                    )
                  : "-"
              }}
            </td>
            <td>
              {{
                item.end_date_time
                  ? moment(new Date(item.end_date_time)).format(
                      "HH:mm DD.MM.YYYY"
                    )
                  : "-"
              }}
            </td>
            <td>
              {{ getDifference(item.start_date_time, item.end_date_time) }}
            </td>
            <td>
              {{ item.fact_amount ?? 0 }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>

  <PlanMaterialModal
    :productId="state.product.product_id"
    :apiRequest="getPlanProducts"
  />
  <PlanTaskModal
    ref="taskModalRef"
    id="plan-task-modal"
    title="Добавить материал"
    submitBtnText="Завершить"
    :entityToUpdate="state.product"
    :timer="timer"
    :padZero="padZero"
    :apiRequest="updateTask"
    :refetch="refetch"
  />
  <TaskFinishModal
    id="task-finish-modal"
    title="Завершить частично"
    submitBtnText="Завершить"
    :measurementOptionsList="state.measurementOptionsList"
    :entityToUpdate="state.product"
    :apiRequest="finishTaskPartially"
    :refetch="refetch"
  />
</template>

<script>
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useVuelidate } from "@vuelidate/core";

import moment from "moment";
import { getDifference } from "@/helpers/dateTimeUtils";
import { convertNumber } from "@/helpers/stringUtils";
import useLoadingModal from "@/mixins/useLoadingModal";
import useTimer from "@/mixins/useTimer";
import { confirmAlert, errorAlert } from "@/helpers/alerts";
import {
  getPlanTask,
  updateTask,
  startTask,
  getPlanProducts,
  finishTaskPartially,
} from "@/api/plan";
import { getMeasurement } from "@/api/measurement";

import PlanMaterialModal from "@/components/plan/MaterialModal.vue";
import PlanTaskModal from "@/components/plan/PlanTaskModal.vue";
import TaskFinishModal from "@/components/plan/TaskFinishModal.vue";

export default {
  components: { PlanMaterialModal, PlanTaskModal, TaskFinishModal },
  setup() {
    const taskModalRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      product: {},
      productId: route.params.id,
      tasksToResolve: [],
      measurementOptionsList: [],
    });
    const { isDataLoading } = useLoadingModal();
    const {
      timer,
      timerFunction,
      startTimer,
      padZero,
      stopTimer,
      deleteTimer,
    } = useTimer(state.productId, startTask);

    const rules = computed(() => ({
      product: {
        id: {},
        title: {},
        quantity: {},
      },
    }));
    const v$ = useVuelidate(rules, state);

    onMounted(() => {
      getData();
    });

    onMounted(async () => {
      try {
        state.measurementOptionsList = await getMeasurement();
      } catch {
        errorAlert("Ошибка сервера!");
      }
    });

    watch(route, () => {
      router.go(0);
    });

    const getData = async () => {
      try {
        isDataLoading.value = true;
        const res = await getPlanTask(state.productId);
        state.product = res.data[0];
        state.tasksToResolve = res.message.map((item) => item.task_id);
      } catch {
        errorAlert();
      } finally {
        isDataLoading.value = false;
      }
    };

    async function onSubmitForm() {
      await this.v$.product.$touch();
      if (this.v$.product.$invalid) return;

      confirmAlert("Вы точно уверены что хотите завершить процесс?").then(
        async (result) => {
          if (result.isConfirmed) {
            // try {
            //   showModal(taskModalRef?.value?.modalRef);
            // } catch {
            //   errorAlert("Ошибка сервера!");
            // }

            try {
              isDataLoading.value = true;
              await updateTask({ id: state.productId });

              deleteTimer();
              router.push({
                name: "formation-page",
                params: { id: parseInt(state.product?.deal_id) },
              });
            } catch {
              errorAlert("Ошибка сервера!");
            } finally {
              isDataLoading.value = false;
            }
          }
        }
      );
    }

    function refetch() {
      // deleteTimer();
      // router.push({
      //   name: "formation-page",
      //   params: { id: parseInt(state.product?.deal_id) },
      // });

      deleteTimer();
      getData();
    }

    return {
      state,
      taskModalRef,
      v$,
      onSubmitForm,
      updateTask,
      getPlanProducts,
      finishTaskPartially,
      convertNumber,
      moment,
      getDifference,

      timer,
      timerFunction,
      startTimer,
      padZero,
      stopTimer,
      refetch,
    };
  },
};
</script>
