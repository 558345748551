import { ref, reactive, onMounted, onUnmounted, watch } from 'vue';
import { errorAlert } from '@/helpers/alerts';

export default function useTimer(productId, startTask) {
  const timer = reactive({
    days: 0,
    hours: 0,
    minutes: 0,
    status: false,
    lastUpdated: null,
  });
  const timerFunction = ref(null);

  onMounted(() => {
    const timerData = localStorage.getItem(`alem-task-${productId}-timer`)
      ? JSON.parse(localStorage.getItem(`alem-task-${productId}-timer`))
      : [];

    if (timerData && timerData.status) {
      const currentTime = new Date();
      const lastUpdatedTime = timerData.lastUpdated ? new Date(timerData.lastUpdated) : null;
      let timeDifference = 0;

      if (lastUpdatedTime) {
        // Difference in milliseconds
        timeDifference = currentTime - lastUpdatedTime;
      }

      // Difference in days, hours, and minutes
      const diffDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const diffMinutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

      timer.status = true;
      timer.lastUpdated = timerData.lastUpdated ?? null;
      timer.days = (timerData.days ? parseInt(timerData.days) : 0) + diffDays;
      timer.hours = (timerData.hours ? parseInt(timerData.hours) : 0) + diffHours;
      timer.minutes = (timerData.minutes ? parseInt(timerData.minutes) : 0) + diffMinutes;

      // Normalization of minutes, hours, and days
      if (timer.minutes >= 60) {
        timer.hours += Math.floor(timer.minutes / 60);
        timer.minutes = timer.minutes % 60;
      }
      if (timer.hours >= 24) {
        timer.days += Math.floor(timer.hours / 24);
        timer.hours = timer.hours % 24;
      }
    } else if (timerData && !timerData.status) {
      timer.status = false;
      timer.lastUpdated = timerData.lastUpdated ?? null;
      timer.days = timerData.days ? parseInt(timerData.days) : 0;
      timer.hours = timerData.hours ? parseInt(timerData.hours) : 0;
      timer.minutes = timerData.minutes ? parseInt(timerData.minutes) : 0;
    }
  });

  const updateTime = () => {
    if (timer.minutes != 59) timer.minutes = timer.minutes + 1;
    else {
      timer.minutes = 0;
      if (timer.hours != 23) timer.hours = timer.hours + 1;
      else {
        timer.hours = 0;
        timer.days = timer.days + 1;
      }
    }

    updateLocalStore();
  };

  const startTimer = async () => {
    if (!timerFunction.value) {
      try {
        if (timer.lastUpdated == null) await startTask(productId, Date.now());

        timerFunction.value = setInterval(updateTime, 60000);
        timer.status = true;
        updateLocalStore();
      } catch {
        errorAlert();
      }
    }
  };

  const stopTimer = () => {
    if (timerFunction.value) {
      clearInterval(timerFunction.value);
      timerFunction.value = null;
      timer.status = false;
    }

    updateLocalStore();
  };

  const deleteTimer = () => {
    timer.days = 0;
    timer.hours = 0;
    timer.minutes = 0;
    timer.status = false;
    timer.lastUpdated = null;
    localStorage.removeItem(`alem-task-${productId}-timer`);

    if (timerFunction.value) {
      clearInterval(timerFunction.value);
      timerFunction.value = null;
    }
  };

  watch(
    () => timer.status,
    () => {
      if (timer.lastUpdated == null) return;

      if (timer.status) startTimer();
      else stopTimer();
    },
  );

  const updateLocalStore = () => {
    timer.lastUpdated = Date.now();
    timer.time = padZero(timer.days) + ':' + padZero(timer.hours) + ':' + padZero(timer.minutes);

    localStorage.setItem(`alem-task-${productId}-timer`, JSON.stringify(timer));
  };

  const padZero = (num) => {
    return num < 10 ? '0' + num : num;
  };

  onUnmounted(() => {
    if (timerFunction.value) {
      clearInterval(timerFunction.value);
      timerFunction.value = null;
    }
  });

  return { timer, timerFunction, startTimer, padZero, stopTimer, deleteTimer };
}
